import { create } from "css-in-bun" with {
    type: "macro"
};
export const styles = create({
    SettingAreaContainer: {
        backgroundColor: "var(--primary-thin)",
        borderRadius: 10,
        position: "relative"
    },
    SettingArea: {
        padding: "8px 12px 0 12px"
    },
    ItemContainer: {
        position: "relative",
        display: "grid"
    },
    SettingAreaInput: {
        "::placeholder": {
            color: "var(--color-half)"
        },
        width: "100%"
    },
    SettingAreaTextArea: {
        minHeight: "calc(9em + 20px)",
        width: "100%",
        paddingBottom: 20,
        lineHeight: "1.5em",
        "::placeholder": {
            color: "var(--color-half)"
        }
    },
    SettingAreaCharacterCounter: {
        position: "absolute",
        right: 16,
        bottom: 8,
        fontSize: 12,
        fontVariantNumeric: "tabular-nums slashed-zero",
        color: "var(--color-semi)",
        fontWeight: 400,
        zIndex: 1
    },
    SettingAreaItem: {
        display: "grid",
        gridTemplateColumns: "max-content 1fr",
        gap: 4,
        marginBottom: 4
    },
    SettingAreaItemLabel: {
        fontSize: 14,
        gap: 4,
        paddingTop: 3,
        display: "flex",
        paddingLeft: 0
    }
});
