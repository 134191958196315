export const formVariants = {
    enter: {
        y: 20,
        opacity: 0
    },
    center: {
        y: 0,
        opacity: 1,
        height: 'auto',
        transition: {
            bounce: 0
        }
    },
    exit: {
        opacity: 0
    }
};
