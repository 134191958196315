function SimpleTransform(input) {
    return input + "";
}
export const CharacterDescriptor = [
    {
        field: "age",
        name: "年龄",
        editor: "age",
        transform: (value)=>value + "岁",
        placeholder: void 0,
        basic: !1,
        defaultValue: 17
    },
    {
        field: "character",
        name: "性格",
        editor: "text",
        transform: SimpleTransform,
        placeholder: void 0,
        basic: !0,
        defaultValue: ""
    },
    {
        field: "hobbies",
        name: "爱好",
        editor: "text",
        transform: SimpleTransform,
        placeholder: void 0,
        basic: !1,
        defaultValue: ""
    },
    {
        field: "professions",
        name: "职业",
        editor: "short-text",
        transform: SimpleTransform,
        placeholder: void 0,
        basic: !0,
        defaultValue: ""
    },
    {
        field: "language_style",
        name: "语言风格",
        editor: "text",
        transform: SimpleTransform,
        placeholder: "角色的说话风格，如活泼、冷淡等",
        basic: !1,
        defaultValue: ""
    }
];
